.checkinly-date-picker {
  padding-left: 10px;
  width: calc(100% - 14px);
  border-radius: 4px;
}

.checkinly-date-picker:focus,
.checkinly-date-picker:focus-visible,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
input:focus,
select:focus,
textarea:focus {
  border-color: #18cb90;
}

input,
select {
  margin-top: 4px;
  margin-bottom: 16px;
  height: 44px !important;
  border: 1px solid #c5d2d9;
  width: 100%;
}
